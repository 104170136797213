
import { Input, Radio, Select } from '@/components/input-elements';
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_CUSTOMER_GROUP, GET_ECONOMIC_NUMBER } from '../../customer-groups/graphql/Queries';
import { GET_PRICE_GROUP } from '../../price-groups/graphql/Queries';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { EditCustomer } from '../interfaces/index';
import { UPDATE_CUSTOMER } from '../graphql/Mutations';
import { EDIT_CUSTOMER } from '../graphql/Queries';
import { useRoute } from 'vue-router';
import router from '@/router';
import { CUP_AGREEMENT_PRODUCT } from '@/modules/catalog/products/graphql/Queries';
import Permissions from '@/modules/permission/permissions/components/Permissions.vue';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    components: {
        Input,
        Radio,
        Select,
        Permissions,
        InnerLoader
    },
    setup() {
        const route = useRoute();
        const cupAgreements = ref([]) as Record<any, any>;
        const customerGroups = ref([]) as Record<any, any>;
        const priceGroups = ref([]) as Record<any, any>;
        const sites = ref([]) as Record<any, any>;
        const phone = ref('');
        const editCustomerForm = ref<null | HTMLFormElement>(null);
        const old_economic_number = ref(null);
        const economic_number_required = ref(false);
        const economic_number_exist = ref(false);
        const store = useStore();
        const loader = ref(false);
        const countries = ref([]) as Record<any, any>;
        const showCountryInfo = ref([]) as Record<any, any>;
        const loading = ref(false);
        const products = ref([]) as Record<any, any>;
        const cup_agreement_products = ref([]) as Record<any, any>;
        const tabs = ref('0');
        const abilities = ref([]) as Record<any, any>;
        const permissionForm = ref<null | HTMLFormElement>(null);
        const i18n = useI18n();

        const customer = ref<EditCustomer>({
            id: null,
            name: '',
            email: '',
            password: '',
            cup_agreement: 0,
            customer_group: null,
            price_group: null,
            site_id: null,
            country_id: null,
            password_confirmation: '',
            phone: '',
            economic_number: null,
            user_id: null,
            status: false,
            locale: ''
        });

        const resetForm = () => {
            editCustomerForm.value?.resetFields();
        };

        const goBack = () => {
            router.push({ name: 'customers_list' });
        };

        const checkCustomerGroup = () => {
            const result = customerGroups.value.find(({ value }) => value === customer.value.customer_group);
            if (result.label != 'B2C') {
                return (economic_number_required.value = true);
            }
            return (economic_number_required.value = false);
        };

        const checkEconomicNumber = () => {
            if (customer.value.economic_number != null && customer.value.economic_number) {
                loader.value = true;
                Apollo.watchQuery({
                    query: GET_ECONOMIC_NUMBER,
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-only',
                    errorPolicy: 'all',
                    variables: {
                        economic_number: customer.value.economic_number,
                        update: old_economic_number.value != customer.value.economic_number ? true : false
                    }
                }).subscribe(({ data, errors }) => {
                    if (errors) {
                        // Notify.error(errors[0].message);
                        economic_number_exist.value = true;
                        loader.value = false;
                    } else {
                        economic_number_exist.value = false;
                    }
                    // economic_number_exist.value = false;
                    loader.value = false;
                });
            }
        };

        // Get Countries
        const getCountries = () => {
            countries.value = [];
            const countriesData = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            const country = [] as Record<any, any>;
            countriesData.countries.forEach(element => {
                country.push({
                    label: element.name,
                    value: element.id,
                    locale: element.locale,
                    currency: element.currency
                });
            });

            countries.value = country;
        };

        getCountries();

        const changeConfig = (id: number) => {
            showCountryInfo.value = [];
            const config = countries.value.find(element => element.value == id);
            showCountryInfo.value.push({
                Currency: config.currency,
                Locale: config.locale
            });
        };

        const getProducts = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: CUP_AGREEMENT_PRODUCT,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                products.value = [];
                data.products.data.forEach(element => {
                    products.value.push({
                        value: element.id,
                        desc: JSON.parse(element.description.name).da.name
                    });
                });

                loader.value = false;
            });
        };

        const editCustomer = (uuid: string) => {
            loader.value = true;
            Apollo.watchQuery({
                query: EDIT_CUSTOMER,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    uuid: uuid
                }
            }).subscribe(({ data }) => {
                const editCustomer = JSON.parse(data?.edit_customer);
                resetForm();
                changeConfig(editCustomer?.user?.country_id);

                if (editCustomer?.in_cup_agreement) {
                    getProducts();
                    if (editCustomer?.cup_agreements.length > 0) {
                        editCustomer.cup_agreements.forEach(element => {
                            cup_agreement_products.value.push(element.id);
                        });
                    }
                }

                if (editCustomer.user.abilities.length > 0) {
                    ``;
                    abilities.value = [];
                    editCustomer.user.abilities.forEach(element => {
                        abilities.value.push(element.name);
                    });

                    permissionForm.value?.assignPermission(abilities.value);
                }

                customer.value.id = editCustomer?.id;
                customer.value.phone = editCustomer?.phone_number;
                customer.value.economic_number = editCustomer?.economic_number;
                old_economic_number.value = editCustomer?.economic_number;
                customer.value.user_id = editCustomer?.user?.id;
                customer.value.name = editCustomer?.user?.name;
                customer.value.email = editCustomer?.user?.email;
                customer.value.country_id = editCustomer?.user?.country_id;
                customer.value.cup_agreement = editCustomer?.in_cup_agreement;
                customer.value.site_id = editCustomer?.site_id;
                customer.value.customer_group = editCustomer?.customer_group_id;
                customer.value.price_group = editCustomer?.price_group_id;
                customer.value.status = editCustomer?.user?.status == 0 ? false : true;
                tabs.value = '0';
                checkCustomerGroup();
                loader.value = false;
            });
        };

        onMounted(() => {
            editCustomer(String(route.params.uuid));
        });

        cupAgreements.value = [
            {
                label: 'message.YES',
                value: 1
            },
            {
                label: 'message.NO',
                value: 0
            }
        ];

        const getCustomerGroups = () => {
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_CUSTOMER_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                customerGroups.value = [];
                data.customer_groups.data.forEach(element => {
                    customerGroups.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
                // loader.value = false;
            });
        };

        getCustomerGroups();

        const getPriceGroup = () => {
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_PRICE_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                priceGroups.value = [];
                data.price_groups.data.forEach(element => {
                    priceGroups.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
                // loader.value = false;
            });
        };

        getPriceGroup();

        const validateNumber = e => {
            if (e.valid == true) {
                Notify.success(i18n.t('message.PHONE_NUMBER_IN_FORMATTED'));
            }
        };

        watchEffect(() => {
            sites.value = [];
            const getSites = store.getters.getAllSites.data.sites;
            getSites.forEach((element, index) => {
                sites.value.push({
                    label: element.name,
                    value: element.id
                });
            });
        });

        const handlerSubmitForm = () => {
            editCustomerForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        id: customer.value.id,
                        user_id: customer.value.user_id,
                        name: customer.value.name,
                        email: customer.value.email,
                        password: customer.value.password,
                        password_confirmation: customer.value.password_confirmation,
                        country_id: customer.value.country_id,
                        site_id: customer.value.site_id,
                        customer_group_id: customer.value.customer_group,
                        price_group_id: customer.value.price_group,
                        phone_number: customer.value.phone,
                        economic_number: customer.value.economic_number ? customer.value.economic_number : null,
                        in_cup_agreement: customer.value.cup_agreement,
                        status: customer.value.status == true ? 1 : 0,
                        cup_agreement_products: cup_agreement_products.value,
                        abilities: abilities.value,
                        locale: showCountryInfo.value[0].Locale
                    };

                    if (economic_number_exist.value == true && customer.value?.economic_number) {
                        Notify.error(i18n.t('message.ECONOMIC_NUMBER_EXISTS'));
                        return;
                    }

                    if (formData.password != '' || formData.password_confirmation != '') {
                        if (formData.password_confirmation == '') {
                            Notify.error(`${i18n.t('message.CONFIRM')} ${i18n.t('message.PASSWORD')} ${i18n.t('message.IS_REQUIRED')}`);
                            return;
                        }
                        if (formData.password == '') {
                            Notify.error(`${i18n.t('message.PASSWORD')} ${i18n.t('message.IS_REQUIRED')}`);
                            return;
                        }
                        if (formData.password != formData.password_confirmation) {
                            Notify.error(i18n.t('message.CONFIRM_PASSWORD_DOES_NOT_MATCH'));
                            return;
                        }
                    }

                    if (formData.abilities.length == 0) {
                        Notify.error(i18n.t('message.ASSIGN_PERMISSIONS'));
                        return;
                    }

                    loader.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_CUSTOMER,
                        variables: { input: formData },
                        update: (store, { data: { update_customer } }) => {
                            router.push({ name: 'customers_list' });
                            loader.value = false;
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loader.value = false;
                    });
                }
            });
        };

        const onChangeCupAgreement = position => {
            if (position) {
                getProducts();
            }
            tabs.value = position == 1 ? '2' : '0';
        };

        const getPermissions = data => {
            abilities.value = [];
            abilities.value = data;
        };

        return {
            customer,
            cupAgreements,
            customerGroups,
            priceGroups,
            sites,
            phone,
            editCustomerForm,
            countries,
            showCountryInfo,
            loading,
            cup_agreement_products,
            products,
            tabs,
            permissionForm,
            loader,
            goBack,
            validateNumber,
            handlerSubmitForm,
            onChangeCupAgreement,
            getPermissions,
            changeConfig,
            economic_number_required,
            economic_number_exist,
            checkCustomerGroup,
            checkEconomicNumber,
            old_economic_number
        };
    }
});
